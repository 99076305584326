.panel{
    background: var(--white);
    border-radius: 5px;
    padding: 30px;

    @include respond-from(sm){
        padding: 30px 14px;
    }

    &-box{
        margin: 0 0 40px;
    }

    &-head{
        color: var(--heading);
        font-size: 17px;
        font-weight: 700;
        width: 100%;
        display: block;
        padding: 0 45px 0 0;
        position: relative;

        @include respond-from(sm){
            font-size: 15px;
        }

        &::after{
            content: "";
            position: absolute;
            display: inline-block;
            width: 30px;
            height: 30px;
            right: 0;
            top: 0;
            border-radius: 50%;
            background: #E4E4E4 url(../../assets/arrowIcon.png) no-repeat center/12px;
            transition-duration: 0.3s;
        }
        
        &.is-open{
            &::after{
                transform: rotate(180deg);
                transition-duration: 0.3s;
            }
        }
    }

    &-content {
        letter-spacing: 0.03px;
        opacity: 0.8;        
        padding: 20px 0 0;
        font-size: 14px;

        & p{
            line-height: 22px;
        }

        & ul{
            margin: 15px 0;
        }
    }
}