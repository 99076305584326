@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap");


*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  // clear: both;
  // user-select: none;
  // -webkit-user-select: none;
}


:root{
  --default : #676767;
  --white : #ffffff;
  --black: #000000;
  --theme: #FF4D40;
  --heading: #474747;
  --light-text: #979797;
  --light: #F9F8F8;
  --red: #D93025;
}


html{
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  // font-size: 10px;
}

body{
  box-sizing: border-box;
  font-size: 15px; 
  color: var(--default);
  background-color: var(--white);
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  // overflow: hidden;

  @include respond-from(sm){
    font-size: 15px; 
  }
  @include respond-from(xs){
    font-size: 14px; 
  }
}





::-webkit-scrollbar {
  width: 6px;  
}

/* Track */
::-webkit-scrollbar-track {
  background: #C1C1C1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--light-gray); 
  // border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--light-gray);     
}


// [data-theme*='dark']{

// }