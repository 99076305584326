@mixin clear-fix {
  &::after{
      content: "";
      clear: both;
      display: table;
  }
}

@mixin grid-generator($size, $grid-columns) {
  @for $i from 1 through $grid-columns {
    .col-#{$size}-#{$i} {
      width: percentage($i / $grid-columns);
      float: left;
    }
  }
}

@mixin text-purple($gradient) {
    color: #422692;
    background: linear-gradient(to right, #C837D4 -1%, #741AAF 30%, #2F81CB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}



@mixin respond-from($media) {
  @if $media == xs {
    @media (min-width: 0) and (max-width: 420px)  { @content; }
  } @else if $media == md_sm {
    @media (min-width: 421px) and (max-width: 600px) { @content; }
  } @else if $media == sm {
    @media (max-width: 600px) { @content; }
  } @else if $media == no-touch-screen-media {
    @media (hover: hover) { @content; }
  } @else if $media == touch-screen-media {
    @media (hover: none) and (pointer: coarse) { @content; }
  } @else if $media == md {
    @media (max-width: 900px) { @content; }
  } @else if $media == lg {
    @media (max-width: 1200px) { @content; }
  } @else if $media == xl {
    @media (min-width: 1201px) { @content; }
  } 
}


