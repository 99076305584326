main{
    overflow: hidden;
}

.banner{
    margin: 50px 0;
    &-content{
        width: 70%;
        margin: auto;

        @include respond-from(lg){
            width: 100%;
            margin: 30px 0;
        } 

        & h1{
            font-size: 40px;
            font-weight: 600;
            color: var(--heading);

            @include respond-from(lg){
                font-size: 36px;
                line-height: 44px;
            }
            
            @include respond-from(md){
                font-size: 31px;
                line-height: 39px;
            }
            
            @include respond-from(sm){
                font-size: 26px;
                line-height: 34px;
            }
        }
    
        & p{
            font-size: 17px;
            font-weight: 600;
            margin: 20px 0;

            @include respond-from(md){
                font-size: 15px;
                margin: 14px 0 20px;
            }
        }

        & .img-group{
            & img{
                width: 140px;  
                
                @include respond-from(sm){
                    width: 125px;  
                }
            }
        }
    }

    &-img{
        width: 85%;
        background: url(../../assets/colorfulBoxes.png) no-repeat center/100%;

        @include respond-from(lg){
            width: 100%;
        } 

        & img{
            width: 83%;
            margin: auto;

            @include respond-from(lg){
                width: 100%;
            } 
        }
    }
}

.features{
    position: relative;
    z-index: -1;

    &::before, &::after{
        content: "";
        position: absolute;
        display: inline-block;
        width: 280px;
        height: 280px;
        z-index: -1;
    }

    
    &::before{
        background: url(../../assets/pinkBox.png) no-repeat left top/contain;
        left: -125px;
        top: -76px;
    }

    
    &::after{
        background: url(../../assets/blueBox.png) no-repeat left top/contain;
        right: -125px;
        bottom: -76px;
    }

    &-col{
        margin-top: 60px;

        @include respond-from(md){
            margin-top: 35px;
        }

        & img{
            width: 60px;
        }

        & h3{
            margin: 23px 0 12px;
            font-size: 22px;
            font-weight: 600;

            @include respond-from(sm){
                margin: 14px 0 10px;
                font-size: 20px;
            }
    
        }
    }
}


.own-dream{
    &-list{
        display: flex;
        margin: 0 -20px;
        flex-flow: wrap;
        justify-content: center;

        & > li{
            padding: 20px;
            max-width: 33.33%;

            @include respond-from(lg){
                padding: 20px ;
            }

            @include respond-from(md){
            max-width: 50%;
            }

            @include respond-from(sm){
                max-width: 100%;
                font-size: 15px;
            }
        }
    }
    &-col{
        background: var(--white);
        padding: 30px;
        border-radius: 10px;
        height: 100%;
       // max-height: -webkit-fill-available;
        

        @include respond-from(sm){
            padding: 30px 17px;
        }

        &-body{
            display: flex;
            flex-flow: column;
            height: calc(100% - 55px);

            &-top{
                flex: 1 1;
                -webkit-box-flex: 1;
                -webkit-flex: 1;
                -moz-box-flex: 1;
            }
         
        }

        & h3{
            font-size: 22px;
            font-weight: 700;
            margin: 0 0 10px;

            @include respond-from(lg){
                font-size: 23px;
            }
            
            @include respond-from(sm){
                font-size: 20px;
            }
        }

        & p{
            font-weight: 600;
        }

        & .price{
            font-size: 34px;
            color: var(--heading);
            font-weight: 600;
            margin: 10px 0 0;

            @include respond-from(lg){
                font-size: 33px;
            }

            @include respond-from(sm){
                font-size: 30px;
            }
        }

        & ul{
            border-top: 1px solid #f5f5f5;
            padding: 10px 0 0;
            margin: 15px 0 30px;

            & li{
                font-size: 14px;
                font-weight: 600;
                margin-top: 10px;
                position: relative;
                padding-left: 24px;

                @include respond-from(sm){
                    font-size: 14px;
                }

                &::before{
                    content: "";
                    position: absolute;
                    width: 9px;
                    background: var(--default);
                    height: 9px;
                    display: inline-block;
                    left: 0;
                    border-radius: 50%;
                    top: 6px;
                }
            }
        }

        & .btn{
            width: 100%;
        }
    }
}


.trust-banner{
    background: var(--theme);
    padding: 40px 0;
    position: relative;

    &::before, &::after{
        content: "";
        background: url(../../assets/particles.png) no-repeat left top/cover;
        position: absolute;
        top: 0;
        display: inline-block;
        width: 250px;
        height: 100%;

        @include respond-from(sm){
            display: none;
        }
    }

    &::before{
        left: 0;
    }

    &::after{
        right: 0;
    }

    & p{
        font-weight: 500 !important;
    }

    & .img-group{
        & img{
            width: 150px;

            @include respond-from(sm){
                width: 125px;
            }
        }
    }
}
