header {
  padding: 15px 0;

  @include respond-from(sm) {
    border-bottom: 1px solid var(--light);
  }

  .logo {
    max-height: 98px;
    margin: -8px 0 0;
    max-width: 150px;
  }

  & .menu {
    @include respond-from(md) {
      margin: 4px 0 0;
    }

    @include respond-from(sm) {
      margin: 18px auto 0;
      display: inline-block;
    }

    &-item {
      & a {
        color: var(--text-light);
        font-size: 13px;
        font-weight: 700;

        &:hover {
          color: var(--theme);
        }
      }
    }

    & li {
      & + li {
        margin-left: 50px;

        @include respond-from(md) {
          margin-left: 28px;
        }

        @include respond-from(sm) {
          margin-left: 17px;
        }
      }
    }
  }
}
