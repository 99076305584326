h1, h2,
h3,
h4, h5,
strong {
    font-weight: 700;
    color: var(--heading);
}

input[type=text],input[type=number],input[type=tel],input[type=email]{
    -webkit-appearance: text !important;
    user-select: text !important;
}

select,input[type=submit]{
    -webkit-appearance: none !important;
    user-select: none !important;
}

input:-ms-input-placeholder{
   color: var(--default);
}

p{
    line-height: 25px;

    @include respond-from(sm){
        line-height: 22px;
    }
}

.tick-icon{
    font-size: 22px;
    display: inline-block;
    transform: rotate(12deg);
    line-height: 24px;
}

img{
    max-width: 100%;
    vertical-align: top;
}

.img-group{
    & img{
        & + img{
            margin-left: 20px;
        }
    }
}

a, a:hover, *:focus{
    text-decoration: none;
    outline: 0 none;
    transition-duration: 0.3s;
}

.section-spacing{
    padding: 80px 0;

    @include respond-from(md){
        padding: 60px 0;
    }
    
    @include respond-from(sm){
        padding: 40px 0;
    }
}

.section-heading{
    & h2{
        font-size: 35px;
        color: var(--heading);
        font-weight: 600;

        @include respond-from(lg){
            font-size: 26px;
        }
        
        @include respond-from(sm){
            font-size: 24px;
        }

    }

    & p{
        font-size: 16px;
        margin-top: 10px;
        line-height: 25px;
        font-weight: 600;

        @include respond-from(lg){
            font-size: 16px;
            line-height: 25px;
        }

        @include respond-from(sm){
            font-size: 15px;
        }    
    }
}

.color {
    &-default {
        color: var(--default);
    }

    &-white {
        color: var(--white) !important;
    } 
    
    &-black {
        color: var(--black);
    } 

    &-theme {
        color: var(--theme);
    }
    &-red{
        color: var(--red);
    }
}

.bg{
    &-white {
        background-color: var(--white);
    }
    &-light{
        background-color: var(--light);
    }
}


.text{
    &-center{
        text-align: center;
    }
    &-left{
        text-align: left;
    }
    &-right{
        text-align: right;
    }
    &-justify{
        text-align: justify;
    }
    &-uppercase{
        text-transform: uppercase;
    }
}

.full-width{
    width: 100% !important;
}

.img-radius{
    border-radius: 10px;
}


.p{
    &20 {
        padding: 20px;
    }

    &10{
        padding: 10px;
    }

    &15{
        padding: 15px;
    }

    &B20 {
        padding-bottom: 20px;
    }

    &T20 {
        padding-top: 20px;
    }

    &T15 {
        padding-top: 15px;
    }

    &Y20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &Y15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &X15{
        padding-left: 15px;
        padding-right: 15px;
    }

    &L20{
        padding-left: 20px;
    }

    &0{
        padding: 0 !important;
    }
}


.m{
    &-auto {
        margin: auto;
    }

    &Y-auto {
        margin: auto;
    }

    &R10 {
        margin-right: 10px;
    }

    &T10 {
        margin-top: 10px;
    }

    &T15 {
        margin-top: 15px;
    }

    &L10{
        margin-left: 10px;
    }

    &L15{
        margin-left: 15px;
    }

    &R15{
        margin-right: 15px;
    }

    &L5{
        margin-left: 5px;
    }

    &R5{
        margin-right: 5px;
    }

    &T5{
        margin-top: 5px;
    }

    &Y10{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &Y20{
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &Y30{
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &B30{
        margin-bottom: 30px;
    }

    &B10{
        margin-bottom: 10px;
    }

    &R30{
        margin-right: 30px;
    }

    &L30{
        margin-left: 30px;
    }

    &T30{
        margin-top: 30px;
    }

    &T40{
        margin-top: 40px;
    }

    &T50{
        margin-top: 50px;
    }

    &X30{
        margin-left: 30px;
        margin-right: 30px;
    }

    &T20{
        margin-top: 20px;
    }
}

.row-reverse{
    flex-direction: row-reverse;
}

.list {

    &-inline {
        li {
            display: inline-block;
        }
    }


    &-block {
        li {
            display: block;
        }
    }
}

.border{
    &-full {
        border: 1px solid var(--border);
    }
    &-bottom {
        border-bottom: 1px solid var(--border);
    }
    &-top {
        border-top: 1px solid var(--border);
    }
}

.video{
    border: 1px solid #ddd;
    background: white;
    position: relative;
    padding-bottom: 55%;
    margin: 60px 0 0;
    height: 0;
    overflow: hidden;
    width: 100%;
    border-radius: 29px;
    box-shadow: 0px 30px 40px #00000014;

    @include respond-from(sm){
        border-radius: 15px;
    } 

    & iframe{
        max-height: 100%;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        object-fit: cover;
        left: 0;
    }
}

.d{
    &-block{
        display: block;
    }
    &-flex{
        display: flex;
    }
    &-none{
        display: none !important;
    }
}

.float{
    &-left{
        float: left !important;
    }
    &-right{
        float: right !important;         
    }
    &-none{
        float: none !important;         
    }
}


@include respond-from(md){
    .d-md-none{
        display: none;
    }
    .d-md-block{
        display: block;
    }
    .md-mt-30{
        margin-top: 30px;
    }
    .md-mt-20{
        margin-top: 20px;
    }
    .md-mt-10{
        margin-top: 10px;
    }
    .text-md-center{
        text-align: center;
    }

}


@include respond-from(sm){
    .text-sm-left{
        text-align: left;
    }

    .text-sm-center{
        text-align: center;
    }

    .d-sm-block{
        display: block !important;
    }

    .d-sm-none{
        display: none !important;
    }

    .sm-mt-10{
        margin-top: 10px;
    }

    .sm-mt-30{
        margin-top: 30px;
    }
   
}


@include respond-from(xs){
    .text-sm-left{
        text-align: left;
    }



}
.error_msg{
    text-align: center;
    font-size: 16px;
    padding: 5px 15px;
    color: var(--red);
    font-weight: 600;
    border: 1px solid var(--red);
    margin: 30px auto 0;
    width: 100%;
    max-width: 400px;
    border-radius: 2px;
}