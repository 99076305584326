[class^="col-"]{
  padding-left: 15px;
  padding-right: 15px;
}

.container{
  max-width: 1200px;
  margin: auto;
  width: 80%;

  @include respond-from(md){
    width: 90%;
} 

}

.row{
  margin-left: -15px;
  margin-right: -15px;
  &::after{
      content: "";
      clear: both;
      display: table;
  }

}


@include respond-from(xl) {
@include grid-generator(xl, 12);
}

@include respond-from(lg) {
@include grid-generator(lg, 12);
}

@include respond-from(md) {
@include grid-generator(md, 12);
}

@include respond-from(sm) {
@include grid-generator(sm, 12);
}

@include respond-from(xs) {
@include grid-generator(xs, 12);
}





