.PopUp {
    position: fixed;
    width: 100%;    
    // width: calc(100% - var(--sidebar-width));
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    left: 0;
    z-index: 100;
    top: 0;
    user-select: none;
    background: rgba(255,255,255,0.6);

    &-container{
        margin: auto;
    }

    &-spinner{    
        border: 7px solid #f3f3f3;
        border-radius: 50%;
        border-top: 7px solid var(--theme);  
        width: 60px;
        height: 60px;
        margin: auto;
        animation: spin 1s linear infinite;
    }
    
    & p.loading-text{
        text-align: center;
        width: 100%;
        margin: 20px 0 0;
        text-shadow: 1px 1px 2px #999; 
        font-size: 18px;
        font-weight: bold;
    }

}

.countryCode{
    display: flex;
        align-items: center;
    &_list{
        position: relative;
        // padding-right: 15px;
        padding-right: 10px;
    }
    & select{
        opacity: 0;
        position: absolute;
        height: 100%;
        width: 100%;
    }
    & input{
        border: 0;
        height: 100%;
        width: 100%;
        color: #6c6c6c;
        padding-left: 15px;
        border-left: 1px solid #e5e5e5;
    }
    & label{
        margin: 0;
        display: flex;
        align-items: center;
        & img{
            padding-left: 10px;
        }
    }
}

.selectCountryCode__control, .selectCountryCode1__control{
    border: 0 !important;
    width: max-content !important;
    &:focus, &:hover{
        box-shadow: none !important;
        border-color: transparent !important;
    }
    
    & .selectCountryCode__input-container {
        margin: 0 !important;
        padding: 0 !important;
    }
    & .selectCountryCode__indicator-separator{
        width: 0 !important;
    }
    & .selectCountryCode__indicator{
        color: var(--heading);
        padding-right:0 !important;
    }
    & .selectCountryCode__value-container {
        padding: 0;
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
