.btn{
    font-size: 15px;
    display: inline-block;
    cursor: pointer;
    font-weight: 600;
    line-height: 20px;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    text-align: center;
    user-select: none;

    // @include respond-from(md){
    //     padding: 14px 30px;
    // }

    &-theme{
        background: var(--theme) !important;
        color: var(--white) !important;
    }

    &-sm{
        padding: 13px 26px;
        font-size: 13px;

        @include respond-from(md){
            padding: 10px 16px;
        }

        @include respond-from(sm){
            font-size: 12px;            
            padding: 8px 13px;
        }
    }
    &-lg{
        padding: 18px 30px;
        font-size: 17px;
        min-width: 200px;

        @include respond-from(md){
            padding: 15px 34px;
            font-size: 16px;
            min-width: auto;
        }

        @include respond-from(sm){
            padding: 12px 32px;
            font-size: 14px;
        }
    }
    
}