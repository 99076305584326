.modal{
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100vh;
    display: flex;
    left: 0;
    top: 0;


    &-container{
        background: var(--white);
        margin: auto;
        max-width: 60%;
        padding: 20px 25px 30px;
        min-width: 400px;
        z-index: 10;
        border-radius: 10px;

        @include respond-from(sm){
            min-width: 300px;
            // max-width: 300px;  
        }
    }

    &-head{
        margin: 0 0 12px;

        & .cross-btn{
            width: 15px;
            vertical-align: middle;
        }
    }

    &-bg{
        width: 100%;
        height: 100vh;
        position: absolute;
        background: rgba(0,0,0,0.3);
    }
}

.form{
    & label{
        color: #6c6c6c;
        font-size: 13px;
        width: 100%;
        margin: 0 0 6px;
        display: block;
    }

    & .form-control {
        width: 100%;
        color: #6c6c6c;
        height: 40px;
        border-radius: 5px;
        border: 1px solid #e5e5e5;
        padding: 5px 10px;
    }    

    & .form-group{
        & + .form-group{
            margin-top: 20px;
        }
    }
}